.playerBody {
    background-color: black;
    height: 100vh;
    
}


.divIframe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    
}

.iframeH3 {
    color: white;
    margin-top: 2.5%;
    font-family: var(--fuente);
    font-size: 2.6em;
    text-transform: capitalize;
    margin-bottom: 0%;
    font-weight: 700;
    
}
.linkPlayer {
    font-family: var(--fuente);
    margin-top: 1%;
    font-size: 1.1em;
    font-weight: 400;
    transition: 0.5s;
    
}
.linkPlayer:hover {
    color: var(--color1);
    text-decoration: underline;
}

.iframePlayer {
    height: 100%;
    width: 65vw;
    
}

@media only screen and (min-width: 1500px){ 
    .iframePlayer {
        height: 100%;
        width: 65vw;
        
    }
}

@media only screen and (max-width: 850px){ 
    .iframeH3 {
        /* width: 95%; */
        margin-bottom: 5%;
        /* margin-top: 30%; */
        font-size: 2.6em;
        margin: 1%;
    }
    .linkPlayer {
        /* width: 95%; */
        font-size: 1em;
        /* margin: 5%; */
    }
    .playerM {
        height: var(--cien);
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        /* padding: 3%; */
    }
    .iframePlaer {
        /* width: 10; */
        /* height: var(--cien); */
        background-color: aliceblue;
    }
    .videoM {
        margin-top: 15%;
        width: 100%;
        /* height: 40%; */
    }
}
