.footer {
  background: rgb(15, 14, 14);
  background: radial-gradient(
    circle,
    rgba(15, 14, 14, 1) 0%,
    rgb(12, 39, 41) 100%
  );
  margin: 0 0;
  display: flex;
  height: 60vh;
  justify-content: space-between;
  
}
.footerMobile {
  background: rgb(15, 14, 14);
  background: radial-gradient(
    circle,
    rgba(15, 14, 14, 1) 0%,
    rgb(12, 39, 41) 100%
  );
  margin: 0 0;
  display: flex;
  min-height: calc(var(--vh, 5vh) * 30);
  justify-content: space-between;
}
.izqFooter {
  width: 45%;
  margin-left: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.izqFooterM {
  width: 90%;
  margin: 0 auto;
  margin-left: 7%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}
.logoFooter {
  margin: 0 0;
  margin-top: 2%;
}
.jmb {
  font-family: var(--fuente);
  color: white;
  font-size: 2.5rem;
  width: 80%;
  font-weight: 600;
  letter-spacing: -1.8px;
}
.dev {
  font-family: var(--fuente);
  color: var(--color1);
  font-size: 1.5rem;
  margin-left: 0.3%;
}
.redesFooter {
  width: 20%;
  display: flex;
  justify-content: space-between;
}
.redesFooterM {
  width: 30%;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-between;
}
.pFooter {
  font-family: var(--fuente);
  color: white;
  font-size: 0.9rem;
  width: 80%;
  font-weight: 400;
}
.pFooterM {
  font-family: var(--fuente);
  color: white;
  font-size: 1rem;
  width: 90%;
  font-weight: 400;
}
.emailFooter {
  font-family: var(--fuente);
  color: white;
  font-size: 0.9rem;
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s;
}
.emailFooterM {
  font-family: var(--fuente);
  color: white;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s;
}
.emailFooter:hover {
  color: var(--color1);
}
.derechos {
  font-family: var(--fuente);
  color: white;
  font-size: 0.8rem;
  font-weight: 200;
  text-decoration: none;
}
.derechosM {
  font-family: var(--fuente);
  color: white;
  font-size: 0.9rem;
  font-weight: 300;
  text-decoration: none;
}
.formFooter {
  display: flex;
  gap: 6.5%;
  width: 45vw;
  flex-wrap: wrap;
  justify-content: center;
}
.derFooter {
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  align-items: center;
}
.input1 {
  width: 42.8%;
  background-color: transparent;
  border: 2px solid transparent;
  border-bottom: 2px solid var(--color1);
  padding: 1.5% 0.5%;
  color: white;
  font-size: 1rem;
  font-family: var(--fuente);
  font-weight: 300;
  transition: 0.3s;
}
.input2 {
  width: 100%;
  font-size: 1rem;
  font-family: var(--fuente);
  font-weight: 300;
  background-color: transparent;
  border: 2px solid transparent;
  border-bottom: 2px solid var(--color1);
  padding: 1.5% 0.5%;
  margin-top: 3%;
  color: white;
  transition: 0.3s;
}
.h3Footer {
  font-family: var(--mplus);
  text-transform: uppercase;
  color: white;
  font-size: 2rem;
  width: 70%;
  text-align: center;
  margin-bottom: 3%;
  font-weight: 800;
}
.inputSubmit {
  margin-top: 6%;
  padding: 1% 8%;
  background-color: var(--color1);
  border: 1px solid transparent;
  border-radius: 10px;
  color: white;
  font-family: var(--fuente);
  font-weight: 500;
  font-size: 1rem;
  opacity: 95%;
  transition: 0.3s;
}
.inputSubmit:hover {
  opacity: 100%;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid var(--color1);
}
.input1:focus {
    outline: 0px solid transparent;
    border: 0px solid transparent;
    border-bottom: 4px solid var(--color1);
    border-left:  2px solid var(--color1);
    font-size: 1rem;
    padding-left: 2%;
    border-radius: 5px;
}
.input2:focus {
    outline: 0px solid transparent;
    border: 0px solid transparent;
    border-bottom: 4px solid var(--color1);
    border-left:  2px solid var(--color1);
  
    padding-left: 2%;
    border-radius: 5px;
}

@media only screen and (max-width: 360px) {
  .redesFooterM {
    width: 40%;
  }
}

@media only screen and (min-height: 800px) and (min-width: 1500px){
  .jmb {
    font-size: 3.5rem;
  }
  .dev {
    font-size: 2.2rem;
  }
  .pFooter {
    font-size: 1.2rem;
  }
  .emailFooter {
    font-size: 1.5rem;
  }
  .derechos {
    font-size: 1.2rem;
  }
  .derFooter {
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .h3Footer {
    font-size: 3.2rem;
  }
}