@import url('https://fonts.googleapis.com/css2?family=Chango&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Nerko+One&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700&family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
.swiper {
  width: 100%;
  height: 100%;
  margin: 0 0;
  background-color: white;
}
.slider {
  height: 100%;
  width: 100%;
  margin: 0 0;


}
.slider6 {
  background-image: url('./img/eventM.png');
  background-position: center;
  background-size: cover;
}
.slider5 {
  background-image: url('./img/companyM.png');
  background-position: center;
  background-size: cover;
}
.slider4 {
  background-image: url('./img/subscription.png');
  background-position: center;
  background-size: cover;
}
.slider3 {
  background-image: url('./img/onlineStoreMobile.png');
  background-position: center;
  background-size: cover;
}
.slider2 {
  background-image: url('./img/businessMobile.png');
  background-position: center;
  background-size: cover;
}
.slider1 {
  background-image: url('./img/professionals.png');
  background-position: center;
  background-size: cover;
}
.serviceLinkMobile {
  
  
  background-color: #0F0E0E;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media only screen and (min-width: 1500px) { 
  .redes h6 {
    font-size: 1.2em;
  }
  .aIno {
    font-size: 1.2em;
  }
.landingDesc2 {
  font-size: 5.2em;
}
.landingBtn2 {
  font-size: 1.4em;
}
.pCaracF {
  font-size: 1.5em;
  text-align: left;
  margin-bottom: 1%;
}
.brand2 {
  margin-bottom: 10%;
  font-size: 2.2em;
}
}

.a404 {
  background: radial-gradient(
    circle,
    rgba(15, 14, 14, 1) 0%,
    rgb(12, 39, 41) 100%
  );
  min-height: var(--cien);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.b404 {
  width: 40%;
}
.h3404 {
  font-family: var(--fuente);
  color: #fff;
  font-size: 2.7rem;
  margin-bottom: 1%;
}
.p404 {
  margin-bottom: 1%;
  font-family: var(--fuente);
  color: white;
  font-size: 1.2rem;
  margin-top: 0;
}
:root {
    --chango: 'Chango', cursive;
    --nerko: 'Nerko One', cursive;
    --open: 'Open Sans', sans-serif;
    --cien: calc(var(--vh, 1vh) * 100); 
    --fuente: 'Montserrat', sans-serif;
    --color1: yellow;
    --mplus: 'Montserrat', sans-serif;
    --mont: 'Montserrat', sans-serif;
    --hue: 223;
    --public: 'Montserrat', sans-serif;
	--bg: #46C2CB;
  --header-text-color: #fff; /* Color de texto predeterminado (puedes ajustarlo según tus necesidades) */
  --header-bg-color: #333;
	--fg: #46C2CB;
  
}
  ::selection {
    background: var(--color1);
    color: black;
 
  }
  ::-moz-selection {
    background: var(--color1);
    color: black;

  }

 /* Estilos para el scrollbar */
::-webkit-scrollbar {
  width: 7px; /* Ancho del scrollbar */
  /* background-color: #0c2224; Color de fondo del scrollbar */
}

/* Estilos para la barra de desplazamiento */
::-webkit-scrollbar-thumb {
 background-color: #322c2c;/* Color de la barra de desplazamiento */
  /* border: 1px solid var(--color1); */
background-size: cover;


}

/* Estilos para la punta del scrollbar (opcional) */
::-webkit-scrollbar-thumb:horizontal {
 /* Borde redondeado para la punta horizontal */
}

::-webkit-scrollbar-thumb:vertical {
 /* Borde redondeado para la punta vertical */
 border-radius: 50px;
  height: 40%;
}
  html {
    margin: 0 0;
    overflow-y: scroll;
    scroll-behavior: smooth;
  
}
body {
    margin: 0 0;
    
}

h5, p {
    margin: 0 0;
}

.a4 {
  background-color: black;
  min-height: calc(var(--vh, 1vh) * 100);
}
.div404 {
  padding-top: 10%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  
}





.bgIn1 {
  -webkit-animation: bg-in1 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: bg-in1 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes bg-in1 {
  0% {
    background: #0F0E0E;
  }

  100% {
    background-image: url('./img/eccomerce2.jpg');
    background-position: center;
    background-size: cover;
  }
}
@keyframes bg-in1 {
  0% {
    background: #0F0E0E;
  }
 
  100% {
    background-image: url('./img/eccomerce2.jpg');
    background-position: center;
    background-size: cover;
  }
}


.bgIn2 {
  -webkit-animation: bg-in2 0.5s linear both;
	        animation: bg-in2 0.5s linear both;
}



@-webkit-keyframes bg-in2 {
  0% {
    background: #0F0E0E;
  }
 
  100% {
    background-image: url('./img/vapearg.png');
    background-position: center;
    background-size: cover;
  }
}
@keyframes bg-in2 {
  0% {
    background: #0F0E0E;
  }
  100% {
    background-image: url('./img/vapearg.png');
    background-position: center;
    background-size: cover;
  }
}





.bgIn3 {
  -webkit-animation: bg-in3 0.5s linear both;
	        animation: bg-in3 0.5s linear both;
}



@-webkit-keyframes bg-in3 {
  0% {
    background: #0F0E0E;
  }
 
  100% {
    background-image: url('./img/startup.jpg');
    background-position: center;
    background-size: cover;
  }
}
@keyframes bg-in3 {
  0% {
    background: #0F0E0E;
  }
  100% {
    background-image: url('./img/startup.jpg');
    background-position: center;
    background-size: cover;
  }
}


.bgIn4 {
  -webkit-animation: bg-in4 0.5s linear both;
	        animation: bg-in4 0.5s linear both;
}



@-webkit-keyframes bg-in4 {
  0% {
    background: #0F0E0E;
  }
 
  100% {
    background-image: url('./img/dolar.png');
    background-position: center;
    background-size: cover;
  }
}
@keyframes bg-in4 {
  0% {
    background: #0F0E0E;
  }
  100% {
    background-image: url('./img/dolar.png');
    background-position: center;
    background-size: cover;
  }
}

.bgIn5 {
  -webkit-animation: bg-in5 0.5s linear both;
	        animation: bg-in5 0.5s linear both;
}



@-webkit-keyframes bg-in5 {
  0% {
    background: #0F0E0E;
  }
 
  100% {
    background-image: url('./img/freelancer.jpg');
    background-position: center;
    background-size: cover;
  }
}
@keyframes bg-in5 {
  0% {
    background: #0F0E0E;
  }
  100% {
    background-image: url('./img/freelancer.jpg');
    background-position: center;
    background-size: cover;
  }
}
@keyframes bg-in6 {
  0% {
    background: #0F0E0E;
  }
  100% {
    background-image: url('./img/artista.jpg');
    background-position: center;
    background-size: cover;
  }
}


.bgIn8 {
  -webkit-animation: bg-in4 0.5s linear both;
	        animation: bg-in4 0.5s linear both;
}



@-webkit-keyframes bg-in8 {
  0% {
    background: #0F0E0E;
  }
 
  100% {
   background: rgb(15,14,14);
  }
}
@keyframes bg-in8 {
  0% {
    background: #0F0E0E;
  }
  100% {
    
        background: radial-gradient(circle, rgba(15,14,14,1) 0%, rgb(12, 39, 41) 100%);
  }
}

.text-focus-in {
	-webkit-animation: text-focus-in 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

 @-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}



.fade-in {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 5px;
  transform: translateY(5px);
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.expandir-txt-arriba {
	-webkit-animation: expandir-txt-arriba 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: expandir-txt-arriba 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

 @-webkit-keyframes expandir-txt-arriba {
    0% {
      letter-spacing: -0.5em;
      -webkit-transform: translateZ(-700px) translateY(-500px);
              transform: translateZ(-700px) translateY(-500px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  @keyframes expandir-txt-arriba {
    0% {
      letter-spacing: -0.5em;
      -webkit-transform: translateZ(-700px) translateY(-500px);
              transform: translateZ(-700px) translateY(-500px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  




 @-webkit-keyframes expandir-txt {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes expandir-txt {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  

.expandir-txt {
	-webkit-animation: expandir-txt 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: expandir-txt 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation-delay: 1.1s;
}




.bgInH {
  -webkit-animation: bg-inH 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: bg-inH 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}



/* @-webkit-keyframes bg-inH {
  0% {
    background: #252525;
  }
 50% {
    background: #161616;
  }
  100% {
    background: hsla(0, 3%, 6%, 0.468);
 
  }
} */
@keyframes bg-inH {
  0% {
    background: hsla(0, 3%, 6%, 0.6);
  }
 50% {
  background: hsla(0, 3%, 6%, 0.5);
  }
  100% {
    background: hsla(0, 3%, 6%, 0.4);
    /* webkitbackdrop-filter: blur(20px); */
  }
}



.scale-in-top {
	-webkit-animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}

.slide-in-left {
  
	-webkit-animation: slide-in-left 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


 @-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-top {
	-webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.type {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  
  white-space: nowrap; /* Keeps the content on a single line */
  
 /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .55s step-end infinite;
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: transparent; }
}





/* TEXT HOME */


.glitch {

  
 
  position: relative;
  text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
    0.025em 0.04em 0 #fffc00;
  animation: glitch 90s infinite;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitch span:first-child {
  animation: glitch 500ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
  transform: translate(-0.04em, -0.03em);
  opacity: 0.75;
}

.glitch span:last-child {
  animation: glitch 375ms infinite;
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
  transform: translate(0.04em, 0.03em);
  opacity: 0.75;
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  15% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  16% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  49% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  50% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  99% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  100% {
    text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff,
      -0.04em -0.025em 0 #fffc00;
  }
}

@media only screen and (max-width: 850px){ 
  .b404 {
    width: 85%;
  }
}

