.precioBody {
  background:black;
  min-height: var(--cien);
}

.precioLand {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: var(--cien);
  align-items: center;
}

.precioIzq {
  width: 50%;
  margin-top: 4%;
  height: calc(var(--vh, 1vh) * 70);
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content:space-evenly;
}

.precioDer {
  
  /* background-color: rgb(60, 255, 0); */
  margin-top: 4%;
  height: calc(var(--vh, 1vh) * 70);
  width: 40%;
  background-repeat: no-repeat;
  background-size: cover;
}
.landingPrecio { 
  background-image: url("../../img/precioD1.png");
  /* width: 30%; */
  margin-right: 5%;
}
.subPrecio { 
  background-image: url("../../img/subscriptionDesk.png");
  width: 35%;
  /* height: 50%; */
  /* margin-right: %; */
}
.aboutPrecio {
  background-image: url('../../img/ABOUT.png');
  
  width: 34%;
  margin-right: 5%;
}
.eventPrecio {
  background-image: url('../../img/eventIc.png');
  
 /* width: 30%; */
   
  margin-right: 0%;
}
.basicaPrecio {
  background-image: url('../../img/basica.png');

}
.ecomercePrecio {
  background-image: url('../../img/ecomercePrecio.png');
}
.cartaPrecio {
  background-image: url('../../img/carta.png');
}
.pedidosPrecio {
  background-image: url('../../img/pedidoA.png');
  margin-right: 0.1%;
}
.businessPrecio {
  background-image: url('../../img/BusinessPlan.png');
  /* width: 10%; */
  /* margin-right: 20%; */
}
.socio {
  background-image: url("../../img/cohete.png");
}

.h5Precio {
    color: #FFF;
font-size: 1.4rem;
font-family: var(--fuente);
margin-left: 14%;
font-weight: 300;
margin-top: 8%;
}
.precioIcon {
   
    font-size: 1.5rem;
    border-radius: 50%;
    color: var(--color1);
}

.h2Precio {
  font-family: var(--fuente);
  font-size: 4.5rem;
  margin: 0 0;
  margin-left: 14%;
  color: white;
    width: 85%;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 1%;
  font-weight: 600;
}

.precioBotonera {
  display: flex;
  flex-direction: row;
    margin-left: 14%;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-top: 2%;

}
.precioContact {
   
    height: calc(var(--vh, 1vh) * 7);
 transition: 0.5s;
  width: 35%;
  /* background-color: transparent; */
  /* border: 1px solid yellow; */
  /* opacity: 97%; */
  color: var(--color1);
  text-transform: uppercase;
  /* border-radius: 5px; */
  /* border: none; */
  outline: none;
  cursor: pointer;
  position: relative;
  /* box-shadow: 0 1px 7px rgba(231, 240, 52, 0.5); */
  overflow: hidden;
  font-family: var(--fuente);
  font-weight: 600;
  background-color: black;
  border: 3px solid #E0E111;
  color: #E0E111;
  font-weight: 600;
  font-size: 1rem;
  padding: 3%;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
  font-family: var(--fuente);
}
.precioContact:active {
    background-color: var(--color1);
    color: #fff;
    opacity: 100%;
  }
  
  .precioContact span {
    font-size: 1rem;
    font-family: var(--fuente);
    font-weight: 600;
    
   
    transition: top 0.5s;
  }
  
  .btn-text-one {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  
  .btn-text-two {
    position: absolute;
    width: 100%;
    top: 150%;
    left: 0;
    transform: translateY(-50%);
  }
  
  .precioContact:hover .btn-text-one {
    top: -100%;
  }
  
  .precioContact:hover .btn-text-two {
    top: 50%;
    font-weight: 500;
  }
  .precioContact:hover {
    opacity: 100%;
    
  }


.precioPrecioDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 8%;
}

.precioPrecio {
    font-size: 1.7rem;
    color: white;
    font-weight: 400;
    font-family: var(--fuente);
}
.precioSep {
    font-size: 3rem;
    color: white;
  
}
.precioDesc {
   font-size: 1rem;
   color: white;
    font-weight: 300;
    font-family: var(--fuente);
    width: 40%;
}
.separador {
    width: 100%;
    overflow: hidden;
    margin: 0 0;
}
.precioCaracs {
    width: 100%;
    /* margin-top: 5%; */
    /* margin-bottom: 5%; */
    background-color: white;
}

.precioCarac {
    display: flex;
    width: 100%;
    margin-top: -4%;
    padding-top: 7%;
    justify-content: space-around;
    align-items: center;
}
.precioCaracTitleDiv {
    display: flex;
}
.precioCaracImgIzqGif {
    height: 18vh;
 
}
.precioCaracImgIzqDiv {
    width: 40%;
}
.precioCaracImgDerDiv {
    /* width: 60%; */
    /* background-color: #fff; */
    display: flex;
    height: 75%;
    justify-content: end;
}
.precioCaracImgIzqImg {
    width: 75%;
}
.precioCaracImgDerImg {
    width: 100%;
}
.precioCaracDer {
    width: 45%;
    
}
.precioCaracIzq {
    width: 45%;
}
.precioCaracTitleDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}
.precioCaracH3 {
    font-size: 2.3rem;
    width: 90%;
    color: black;
    font-family: var(--fuente);
    font-weight: 600;
    /* padding-bottom: 4%; */
    /* border-bottom: 1px solid black; */
}

.caracDesc {
    display: flex;
    justify-content: space-between;
    
}
.caracDescRes {
    
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: left;
}
.caracRes {
    font-size: 1.2em;
    /* text-transform: uppercase; */
    color: black;
    font-family: var(--fuente);
    width: 100%;
    font-weight: 600;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.iconCarac {
  color: white;
  font-weight: 800;
  font-size: 1.1em;
}
.iconCarac {
    background-image: url('../../img/back.png');
    background-size: cover;
    
    padding: 2.5%;
    margin-right: 2.5%;
    border-radius: 50%;
}
.lineaCarac {
    margin: 0 0;
    border: 0.5px solid black;
}

.caracP {
    width: 80%;
    font-size: 1.1rem;
    color: black;
    font-weight: 500;
    font-family: var(--fuente);
    margin-top: 0%;
    margin-bottom: 10%;

}

.precioPricing {
  /* display: flex; */
  min-height: calc(var(--vh, 1vh) * 100);
  
  background-color: black;
  /* background-image: url('../../img/back.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.h2PreciosPricing {
  color: white; /* Set text color to transparent */
  font-family: var(--fuente);
  font-size: 3em;
  margin-top: 2%;
  margin-bottom: 1%;

  /* text-transform: uppercase; */
 
  
}

.pricingProd {
  color: black;
  border: 3px solid black; /* Fallback border style */
  border-image: url('../../img/back.png') 30 round;
  border-width: 3px;
  border-repeat: round;
   
  color: white;
  padding: 1% 3%;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--fuente);
  margin-bottom: 1%;
}


.preciosPricing {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 4%;
}
.precioCard {
  height: calc(var(--vh, 1vh) * 50);
  /* background-color: aliceblue; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 28%;
  background-image: url('../../img/back.png');
  background-size: cover;
  border-radius: 10px;
  padding: 1% 3%;
  /* box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25); */
  /* border: 2px solid white; */
  transition: 0.3s;
  
}

.precioCard2 {
  position: relative;
  height: calc(var(--vh, 1vh) * 50);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 28%;
  background-color: transparent;
  padding: 1% 3%;
  

  transition: 0.3s;
  border-radius: 8px; /* Ajusta este valor para redondear más los bordes */
  overflow: hidden; /* Asegura que la imagen de borde no se desborde */
}

.precioCard2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 6px solid transparent; /* Transparente para dejar espacio para el borde personalizado */
  border-image: url('../../img/back.png') 30 round;
  border-width: 5px;
  border-repeat: round;
  border-radius: 20px; /* Ajusta este valor para redondear más los bordes */
  z-index: -1; /* Coloca el borde detrás del contenido principal */
}


/* .precioCard2:hover {
  transform: scale(1.05);
  
} */
.precioCard:nth-child(1) {
  margin-right: 3%;
}
.precioCardFlex {
  display: flex;
  justify-content: space-between;
  height: 75%;
  margin-top: 2%;
}
.izq {
  border-right: 1px solid rgb(255, 255, 255);
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.der {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
}
.membresiaPCard {
  color: white;
  border: 3px solid white;
  width: 50%;
  padding: 1%;
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
  /* border-radius: 6px; */
  font-family: var(--fuente);
  margin-bottom: 0;
}
.membresiaPCard2 {
  border: 3px solid black; /* Fallback border style */
  border-image: url('../../img/back.png') 30 round;
  border-width: 3px;
  border-repeat: round;
  color: white;
  width: 50%;
  padding: 1%;
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 7px;
  font-family: var(--fuente);
  margin-bottom: 0;
}
.precioCardPrecio {
  font-size: 3.2rem;
  font-weight: 600;
  border-radius: 7px;
  font-family: var(--fuente);
  color: #FFF;
  margin: 0 0;
}
.precioCardForma {
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 7px;
  font-family: var(--fuente);
  color: #FFF;
  text-decoration: underline;
}


.cardPrecioUl {
  color: #FFF;
  list-style: none;
  margin-top: 10%;
}
.cardPrecioUl li {
  font-size: 1rem;

  font-family: var(--fuente);
  display: flex;
 align-items: center;
 margin-bottom: 3%;
  color: #FFF;
   font-weight: 500;
}
.cardPrecioUl li span{
  font-size: 1rem;
  /* margin-bottom: 2%; */
  font-family: var(--fuente);
  margin-right: 5%;
 
 
}

.cardPrecioUl2 {
  color: white;
  list-style: none;
  margin-top: 10%;
}
.cardPrecioUl2 li {
  font-size: 1rem;
  
  font-family: var(--fuente);
  margin-bottom: 3%;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 500;
}
.cardPrecioUl2 li span{
  font-size: 1rem;
  /* margin-bottom: 2%; */
  font-family: var(--fuente);
  margin-right: 5%;
  color: #f99ad0;
 
}
.buttonPricingCard {
  font-family: var(--fuente);
  background-color: transparent;
  padding: 2%;
  border-radius: 10px;
  color: white;
  /* text-transform: uppercase; */
  border: 2px solid white;
  /* box-shadow: 1px 3px 0px 0px #ffffff; */
  font-weight: 800;
  
  transition: 0.2s;
}

.buttonPricingCard:hover {
  cursor: pointer;
  cursor: pointer;
  transform: scale(1.05);
}

.buttonPricingCard2 {
  font-family: var(--fuente);
  background: url('../../img/back.png');
  background-size: cover;
  background-position: top;
  padding: 2%;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  border: 1px solid transparent;
  /* box-shadow: 1px 3px 0px 0px white; */
  
  
  transition: 0.2s;
}

.buttonPricingCard2:hover {
  cursor: pointer;
  transform: scale(1.05);
  
}


.sociedadLink {
  color: white;
  margin-top: 4%;
  margin-bottom: 2%;
  font-family: var(--fuente);
  font-size: 1rem;
  transition: 0.2s;
}
.sociedadLink:hover {
  color: #f99ad0;

} 
.sociedadLink {
  width: 90%;
  text-align: center;
}
.animacion-horizontal {
    animation: mover-horizontalmente 5s infinite linear;
  }
  /* .animacion-horizontal2 {
    animation: mover-horizontalmente2 7s infinite linear;
  } */
  .pricingProd {
    margin-top: 3%;
  }
.desk {
  margin: 0 0;
}
.precioBody {
  margin: 0 0;
}
  .interestedAB {
    /* background: ; */

    color: white;

    background-size: cover;
    background-position: center;
    margin: 0 0;
    height:  calc(var(--vh, 1vh) * 75);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .interestedTop {
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .interestedAB h2 {
    font-family: var(--fuente);
    font-size: 3.7em;
    text-transform: capitalize;
    width: 80%;
    text-align: center;
    
  }
  .abLink {
    font-family: var(--fuente);
    padding: 1% 2%;
    font-size: 1.1em;
    font-weight: 700;
    color: black;
    border: 3px solid black; /* Fallback border style */
    border-image: url('../../img/back.png') 30 round;
    border-width: 3px;
    border-repeat: round;
     transition: 0.2s;
    color: white;
  }
  .abLink:hover {
    background-color: #f99ad0;
  }
  
  .interestedBottom {
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
  }
  .intBottomLeft {
    margin-left: 2%;
  }
.intBottomLeft h4 {
  font-family: var(--fuente);
  font-size: 1.1em;
  font-weight: 500;
  transition: 0.2s;
  margin: 0 0;
}
.intBottomLeft h4:hover {
  color: #f99ad0;
  cursor: pointer;
  text-decoration: underline;
}
.intBottomLeft h4:first-child {
  font-family: var(--fuente);
  font-size: 1.2em;
  font-weight: 800;
  letter-spacing: -0.7px;
  margin: 0 0;
}
.intBottomLeft h4:hover {
  color: white;
  cursor:text;
  text-decoration: none;
}
.intBottomRight {
  margin-right: 2%;
  display: flex;
  flex-direction: column;
}
.intBottomRight h4 {
  font-family: var(--fuente);
  font-size: 1.1em;
  font-weight: 500;
  transition: 0.2s;
  margin: 0 0;
}


.intBottomRight h4:hover {
  color: #f99ad0;
  cursor: pointer;
  text-decoration: underline;
}

.precioPrecioDivAb{
  color: white;
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
 
}
.precioPrecioAb {
  font-family: var(--fuente);
  font-size: 1.7em;
  font-weight: 500;
  margin-left: 20%;
}
.precioDescAb {
  font-family: var(--fuente);
  font-size: 1em;
  width: 50%;
  font-weight: 300;
}
.precioSepAb {
  font-family: var(--fuente);
  font-size: 4em;
  /* width: 25%; */
margin: 1%;
  font-weight: 100;
}

.iconAb {
  color: #f99ad0;
  
}

  @keyframes mover-horizontalmente {
    0% {
      transform: scale(1)
    }
    50% {
        transform: scaleY(1.1)
    }
    100% {
      transform: scaleY(1)
    }
  }

  @keyframes mover-horizontalmente2 {
    0% {
      transform: scale(1)
    }
    50% {
        transform: scaleY(1.02);
        transform: scaleX(1.02);

        
    }
    100% {
      transform: scaleY(1);
      transform: scaleX(1);
    }
  }

 
.sociedadLink {
  transition: 0.3s;
}
  .sociedadLink:hover {
text-decoration: underline;
  }


  @media only screen and (max-width: 850px){


.membresiaPCard {
  width: 60%;
  margin-bottom: 2%;
  font-size: 1em;
  padding: 2%;
}
.buttonPricingCard {
  font-size: 1em;
}
.buttonPricingCard2 {
  font-size: 1em;
  margin-bottom: 5%;
}
.membresiaPCard2 {
  width: 60%;
  margin-bottom: 2%;
  font-size: 1em;
  padding: 2%;
}
.h2PreciosPricing {
  font-size: 3.2rem;
  width: 100%;
  /* background-color: #f99ad0; */
}
.pricingProd {
  font-size: 1.3rem;
  padding: 2%;
}
    .precioCardPrecio {
      font-size: 4em;
      margin-bottom: 2%;
    }
    .precioCardForma {
      margin-bottom: 2%;
    }

    .preciAb {
      width: 35%;
    }
    .precioPrecioDiv {
      width: 80%;
    }
    .txtPrecios2 {
      margin: 0 auto;
      margin-top: 3%;
      width: 90%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .h5Precio {
        font-size: 1.2rem;
        /* margin: 0 0; */
    }
    .separador {
      
      margin-top: 5%;
      margin-bottom: 5%;
      
    }
    
    .precioIzqM {
      margin-top: 10%;
      width: 100%;
        
    }
    .pricingProd {
      padding: 2% 10%;
      margin-bottom: 2%;
      font-size: 1.2rem;
    }
    .sociedadLink {
      margin-bottom: 10%;
      margin-top: 10%;
    }
    .precioLandM {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 22%;
        margin-bottom: 10%;
    } 
    /* .separador {
      height: 25vh;
    } */
    .precioIcon {
        font-size: 1.2rem;
    }
    .h2Precio {
        font-size: 2.7rem;
        /* margin: 0 0; */
    }
    .precioDerM {
        height: calc(var(--vh, 1vh) * 35);
        margin: 0 auto;
        margin-top: 5%;
        width: 100%;
      
        background-size: cover;
        background-position: center;
    }
    .landingPrecio { 
      background-image: url("../../img/precioD1.png");
      height: calc(var(--vh, 1vh) * 45);

    }
    .preciAb {
      font-weight: 500;
    }
    .precioDescAB {
      color: white;
      font-family: var(--fuente);
      font-size: 0.9em;
      width: 40%;
      font-weight: 400;
      margin-right: 6%;
    }
    .aboutPrecio {
      background-image: url('../../img/ABOUT.png');
      
      height: calc(var(--vh, 1vh) * 45);
      
    }
    .cartaPrecio {
      height: calc(var(--vh, 1vh) * 40);
      width: 100%;
    }
    .pedidosPrecio{ 
      height: calc(var(--vh, 1vh) * 45);
    }
    .socio {
      background-image: url("../../img/cohete.png");
      height: calc(var(--vh, 1vh) * 55);
      width: 100%;
    }
    .basicaPrecio {
      width: 75%;
    }
    .ecomercePrecio {
      height: calc(var(--vh, 1vh) * 45);
      width: 90%;

    }
    .precioBotoneraM {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .precioPrecioDiv {
        margin-bottom: 0;
    }
    .precioContactM {
          background-color: transparent;
        padding: 2% 2%;
        width: 70%;
        color: var(--color1);
        border: 2px solid var(--color1);
        /* border-radius: 10px; */
        font-family: var(--fuente);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.1em;
        margin-top: 0;
        text-align: center;
        /* margin-left: 10%; */
    }
    
    .slide-in-left {
      -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
              animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

/* ----------------------------------------------
 * Generated by Animista on 2023-12-7 21:54:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

    .precioCaracsM {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        
        flex-direction: column;
    }
    .precioCaracM {
        width: 90%;
        margin: 0 auto;
        margin-top: 10%;
    }
    .precioCaracImgMdiv {
        width: 100%;
        
    }
    .precioCaracImgIzqImg {
        width: 100%;
    }
.precioCaracH3 {
    width: 100%;
    margin-top: 0;
}
.caracDescRes {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
}
.precioPricing {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.precioCaracsM {
  padding-bottom: 10%;
  background-color: white;
}
.preciosPricing {
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
   margin: 0 0;
   
}
.precioCard {
  flex-direction: column;
  min-height: 35rem;
  flex-direction: column;
  width: 80%;
  margin: 0 0;
  margin-top: 10%;
  margin-bottom: 10%;
}
.precioCard2 {
  flex-direction: column;
  min-height: 35rem;
  flex-direction: column;
  width: 80%;
  margin: 0 0;
  margin-right: 2.3%;
  margin-top: 5%;
}
.preciosPricing {
  width: 100%;
  align-items: center;
  justify-content: center;
} 
.precioCardFlex {
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: start;
  margin: 0 0;
}
.izq {
  border-right-color: transparent;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid white;
  padding-bottom: 2%;
  margin: 0 0;
}
.der {
  width: 100%;
  align-items: start;
  justify-content: center;
  /* background-color: #FFF; */
  text-align: left;
  
  margin: 0 0;
}
.buttonPricingCard {
  margin-top: 2%;
  margin-bottom: 4%;
  padding: 3%;
}
.buttonPricingCard2 {
  margin-top: 2%;
  margin-bottom: 4%;
    padding: 3%;

}
.precioCardForma {
  text-decoration: none;
}
.precioCard2 {
  
  margin-right: 0%;
  
}

.cardPrecioUl2 li span{
    /* margin-left: 4%; */
}
.cardPrecioUl li span{
  /* margin-left: 4%; */
}
.precioCard:nth-child(1) {
  margin-right: 0%;
}
.pricingProd {
  margin-top: 5%;
}
.caracP {
  width: 100%;
  
}
.cartaPrecio {
  width: 70%;
}
/* .pedidosPrecio {
  width: 70%;
} */


.precioPrecio {
  font-size: 1.9rem;
  color: white;
  /* font-weight: 500; */
  font-family: var(--fuente);
}
.precioDesc {
  font-size: 1.2rem;
}

  }



  @media only screen and (min-height: 800px) and (min-width: 1500px){ 
    .precioCaracH3 {
      font-size: 2.5rem;
    }
    .caracP {
      font-size: 1.2rem;
    }
    .caracRes {
      font-size: 1.2rem;
    }
    .precioContact span {
      font-size: 1.4rem;
    }
   .h2Precio {
    font-size: 5.5rem;
   }
   .precioPrecio{
    font-size: 2.2rem;
   }
   .precioDesc {
    font-size: 1.2rem;
   }
   .cardPrecioUl li {
    font-size: 1.3rem;
   }
   .cardPrecioUl2 li {
    font-size: 1.3rem;
   }
   .precioCardPrecio {
font-size: 4.5rem;
   }
   .membresiaPCard {
    width: 65%;
    font-size: 1.2rem;
   }
   .membresiaPCard2 {
    width: 65%;
    font-size: 1.2rem;
   }
   .precioCardForma {
    font-size: 1.5rem;
   }
   .sociedadLink {
    font-size: 1.5rem;
   }
   .h2PreciosPricing {
    font-size: 4rem;
   }
   .pricingProd {
    font-size: 1.2rem;
   }
  }


  .h6Precio {
    color: white;
    font-family: var(--fuente);
    font-size: 1.2em;
  }
  @media only screen and (min-width: 1500px) {  
    
    .h5Precio {
      font-size: 2em;
    }
    .precioIcon {
      font-size: 1em;
    }
    .h2Precio {
      font-size: 6.5em;
    }
    .precioPrecio {
      font-size: 3em;
    }
    .precioCaracH3 {
      font-size: 3.8em;
    }
    .caracRes {
      font-size: 1.5em;
    }
    .caracP {
      font-size: 1.5em;
    }
    .precioCaracImgDerDiv {
      width: 35%;
    }
    .cardPrecioUl li {
      font-size: 1.5em;
    }
    .cardPrecioUl2 li {
      font-size: 1.5em;
    }
    .precioCardPrecio {
      font-size: 5.5em;
    }
    
    .membresiaPCard {
      font-size: 1.5em;
    }
    .membresiaPCard2 {
      font-size: 1.5em;
    }
    .precioCardForma {
      font-size: 1.7em;
    }
    .buttonPricingCard {
      font-size: 1.2em;
    }
    .buttonPricingCard2 {
      font-size: 1.2em;
    }
    .aboutPrecio {
      background-image: url('../../img/ABOUT.png');
      
      width: 36%;
      margin-right: 3%;
    }
    .precioPrecioAb {
      font-family: var(--fuente);
      font-size: 2.2em;
      font-weight: 500;
      margin-left: 20%;
    }
    .precioDescAb {
      font-family: var(--fuente);
      font-size: 1.3em;
      width: 50%;
      font-weight: 300;
    }
    .precioSepAb {
      font-family: var(--fuente);
      font-size: 4em;
      /* width: 25%; */
    margin: 1%;
      font-weight: 100;
    }




    .interestedAB {
      /* background: ; */
  
      color: white;
  
      background-size: cover;
      background-position: center;
      margin: 0 0;
      height:  calc(var(--vh, 1vh) * 75);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .interestedTop {
      align-self: center;
      justify-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .interestedAB h2 {
      font-family: var(--fuente);
      font-size: 5em;
      text-transform: capitalize;
      width: 80%;
      text-align: center;
      
    }
    .abLink {
      font-family: var(--fuente);
      padding: 1% 2%;
      font-size: 1.7em;
      font-weight: 700;
      color: black;
      border: 3px solid black; /* Fallback border style */
      border-image: url('../../img/back.png') 30 round;
      border-width: 3px;
      border-repeat: round;
       transition: 0.2s;
      color: white;
    }
    .abLink:hover {
      background-color: #f99ad0;
    }
    
    .interestedBottom {
      display: flex;
      justify-content: space-between;
      margin-top: 2%;
    }
    .intBottomLeft {
      margin-left: 2%;
    }
  .intBottomLeft h4 {
    font-family: var(--fuente);
    font-size: 1.4em;
    font-weight: 500;
    transition: 0.2s;
    margin: 0 0;
  }
  .intBottomLeft h4:hover {
    color: #f99ad0;
    cursor: pointer;
    text-decoration: underline;
  }
  .intBottomLeft h4:first-child {
    font-family: var(--fuente);
    font-size: 1.6em;
    font-weight: 800;
    letter-spacing: -0.7px;
    margin: 0 0;
  }
  .intBottomLeft h4:hover {
    color: white;
    cursor:text;
    text-decoration: none;
  }
  .intBottomRight {
    margin-right: 2%;
    display: flex;
    flex-direction: column;
  }
  .intBottomRight h4 {
    font-family: var(--fuente);
    font-size: 1.4em;
    font-weight: 500;
    transition: 0.2s;
    margin: 0 0;
  }
  
  
  .intBottomRight h4:hover {
    color: #f99ad0;
    cursor: pointer;
    text-decoration: underline;
  }


  }


  @media only screen and (min-width: 200px) and (max-width: 600px) {
    .cardPrecioUl li {
      font-size: 1.1rem;
    }
    .cardPrecioUl2 li {
      font-size: 1.1rem;
    }
    .aboutPrecio {
      background-image: url('../../img/ABOUT.png');
      
      height: calc(var(--vh, 1vh) * 55);
      
    }
  
  }