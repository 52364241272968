.precios {
    /* background: rgb(15,14,14);
    background: radial-gradient(circle, rgba(15,14,14,1) 0%, rgb(12, 39, 41) 100%); */
   background-color: white;
    background-position: center;
    background-size: cover;
    min-height: 120vh;
}
.linkLandingPrecios:hover {
  cursor: pointer;
}
.txtPrecios {
    margin: 0 auto;
    width: 70%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.h2Precios {
    color: black;
    margin-top: 0;
    font-family: var(--fuente);
    font-size: 3.2em;
    width: 75%;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    /* margin-bottom: 1%; */
    -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.mobilePrecios {
  background-color: black;
}
.color {
  color: var(--color1);
}


.upperPrecios {
  display: flex;
  padding-top: 6%;
  width: 90%;
  margin: 0 auto;
  /* padding-bottom: 2%; */
  margin-bottom: 4%;

  /* align-items: center; */
  justify-content: space-between;
}
.preciosShowcase {
  display: flex;
  /* padding-top: 10%; */
  margin: 0 auto;
  width: 90%;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 10%;
  margin-bottom: 0;
}
.leftUpper {
  width: 50%;
  /* background-color: aqua; */
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */

  /* flex-direction: column; */
  height: calc(var(--vh, 1vh) * 12);
}
.rightUpper {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 12);
  /* background-color: aqua; */
  margin-bottom: 2.5%;
}
.selector {
  width: 50%;
  height: 100%;
  display: flex;
  /* background-color: antiquewhite; */
  flex-direction: column;
  justify-content: space-between;
  
}

.imgSelected {
  width: 60%;
  margin-top: 1%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.imgSelected img {
  width: 100%;
}
.txtSelector {
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */

}
.optionSelector {
  color: black;
  font-family: var(--fuente);
  font-weight: 400;
  font-size: 2.4em;
  margin-bottom: 1%;
  width: 55%;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
}
.optionSelector:hover {
  text-decoration: underline;
}
.h6Paquetes {
  font-size: 1.5rem;
  font-weight: 200;
  font-family: "Public Sans", sans-serif;
  color: white;
  margin-top: 5%;
}
.selected {
  text-decoration: underline;
  text-underline-offset: 8px;
}
.h6Precios {
  padding-bottom: 2%;
  /* font-weight: 900; */
}
.learnMore {
  font-family: var(--fuente);
  border: 2px solid black;
  padding: 1.5% 4%;
  color: #000;
  font-weight: 600;
  transition: 0.3s;
}
.learnMore:hover {
  background-color: #000;
  color: white;
}




.landingPrecios {
  height: var(--cien);
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */
  /* align-items: center; */
  justify-content: center;
  background-image: url('../../img/anc.jpg');
  background-size: cover;
}
.h2LandingPrecios {
  font-size: 5em;
  color: white;
  font-family: var(--fuente);
  font-weight: 600;
  margin-left: 4.5%;
  margin-bottom: 0;
}
.linkLandingPDiv {
  display: flex;
  align-items: center;
}
.linkLandingPrecios {
  margin-left: 5%;
  font-size: 1.3em;
  border: 1px solid white;
  background-color: white;
  color: #000;
  padding: 1.5% 2.2%;
  transition: 0.4s;
}
.linkLandingPDiv h6 {
  color: white;
  font-size: 1em;
  font-weight: 40  0;
  margin-left: 2%;
}
.linkLandingPrecios:hover {


  opacity: 85%;
}
.linkLandingPrecios {
  
  border: 2px solid white;
}

 @-webkit-keyframes focus-in-expand {
    0% {
      letter-spacing: -0.5em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes focus-in-expand {
    0% {
      letter-spacing: -0.5em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  
.u {
    color: var(--color1);
}
.h6Precios {
    font-family: var(--fuente);
    font-size: 1rem;
    color: black;
    font-weight: 500;
    margin-top: 0;
   
    margin-bottom: 0;
    -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	  animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@media only screen and (min-width: 1500px) {
  .h2Precios {
    font-size: 4em;
  }
  .h6Precios {
    font-size: 1.3em;
  }
}
.afiliacionP {
    color: white;
    font-family: var(--fuente);
    font-size: 1.2rem;
    font-weight: 600;
    /* margin-top: 20%; */
}

.pricings {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3%;
    flex-direction: row;
}
.qr {
    background-image: url('../../img/qr.png');
}
.delivery {
    background-image: url('../../img/delivery.jpg');
}
.linkLandingPrecios {
  font-family: var(--fuente);
  font-weight: 500;
}
.h6LandingPrecios{
  font-family: var(--fuente);
  font-weight: 500;
}
@media only screen and (max-width: 850px){ 
.preciosLandingM {
  background: url('../../img/ABOUT3.png');
  background-size: cover;
  background-position: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: space-between;
}
.antesDeMidMobile {
  background-color: #EFEBE2;
  margin: 0 0;
}
.antesDeMidMobile h4 {
  margin: 0 0;
  margin: 0 5%;
  font-size: 1.2em;
  font-family: var(--fuente);
}
.antesDeMidMobile h6 {
  margin: 0 0;
}
.linkLandingPrecios {
  margin: 0 0;
  margin-bottom: 5%;
  width: 85%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

  .h2LandingPrecios {
    font-size: 3.8em;
    letter-spacing: -2.5px;
 font-family: var(--fuente);
    text-align: center;
    font-weight: 600;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
  /* text-shadow: 1px 1px 3px gray; */
    margin: 0 0;
  }
  .h6LandingPrecios {
    padding-top: 25%;
    letter-spacing: 2.5px;
    font-size: 1.1em;
    width: 80%;
    margin: 0 0;
    /* margin-top: 4%; */
    margin-bottom: 2%;
    text-align: center;
    color: black;
    text-transform: uppercase;
    /* text-shadow: 1px 1px 1px rgba(144, 143, 143, 0.332); */
  } 

  .linkLandingPrecios {
    position: absolute;
    /* z-index: 999; */
    bottom: 0;
    margin-bottom: 3%;
    left: 5%;

  }
  .divTxtPLan {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
    .h2Precios {
        margin-top: 25%;
    }
    .txtPrecios {
        width: 90%;
        margin: 0 auto;
    }
    .h2Precios {
      color: white;
      width: 100%;
      padding-bottom: 4%;
    }
    .h6Precios {
      color: white;
    }
    .txtPrecios {
      padding-top: 20%;
    }
    .midPrecios {
      height: var(--cien);
    }
}

@media only screen and (min-width: 1500px) { 
.h2LandingPrecios {
  font-size: 6em;
  margin-bottom: 2%;
}
.linkLandingPrecios {
  font-size: 1.6em;
}
.linkLandingPDiv h6 {
  font-size: 1.2em;
}
 .h2Precios {
  font-size: 4.9em;
 } 
 .txtSelector {
  font-size: 1.5em;
 }
 .h6Precios {
  font-size: 1.4em;
 }
 .learnMore {
  font-size: 1.2em;
 }
}



.h2Precios {
  margin: 0 0;
}
.separador3 {
  width: 100%;
  margin: 0 0;
  overflow: hidden;
}