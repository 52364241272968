.spinner {
	
	color: var(--fg);
	font: 1em/1.5 sans-serif;

	
	transition: background-color 0.3s;
}
.spinnerbg {
    background: rgb(15,14,14);
    background: radial-gradient(circle, rgba(15,14,14,1) 0%, rgb(12, 39, 41) 100%);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
main {
	padding: 1.5em 0;
}

.ap {
	width: 4em;
	height: 8em;
}
.ap__ring {
	stroke:#46C2CB;
	transition: stroke 0.3s;
}
.ap__worm1,
.ap__worm2 {
	animation-duration: 2.5s;
	animation-iteration-count: infinite;
}
.ap__worm1 {
	animation-name: worm1;
}
.ap__worm2 {
	animation-name: worm2;
	visibility: hidden;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: #46C2CB;
		--fg: #46C2CB;
	}
	.ap__ring {
		stroke: hsla(var(--hue),10%,90%,0.1);
	}
}

/* Animtions */
@keyframes worm1 {
	from {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: -87.96;
	}
	20% {
		animation-timing-function: ease-in;
		stroke-dashoffset: 0;
	}
	60% {
		stroke-dashoffset: -791.68;
		visibility: visible;
	}
	60.1%,
	to {
		stroke-dashoffset: -791.68;
		visibility: hidden;
	}
}
@keyframes worm2 {
	from,
	60% {
		stroke-dashoffset: -87.96;
		visibility: hidden;
	}
	60.1% {
		animation-timing-function: cubic-bezier(0,0,0.5,0.75);
		stroke-dashoffset: -87.96;
		visibility: visible;
	}
	77% {
		animation-timing-function: cubic-bezier(0.5,0.25,0.5,0.88);
		stroke-dashoffset: -340;
		visibility: visible;
	}
	to {
		stroke-dashoffset: -669.92;
		visibility: visible;
	}
}