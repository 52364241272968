@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');

body {
    margin: 0 0;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-3-11 15:34:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-flicker-in-glow
 * ----------------------------------------
 */
 /* ----------------------------------------------
 * Generated by Animista on 2024-3-11 15:38:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-flicker-in-glow
 * ----------------------------------------
 */
@-webkit-keyframes text-flicker-in-glow {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
      text-shadow: none;
    }
    10.1% {
      opacity: 1;
      text-shadow: none;
    }
    10.2% {
      opacity: 0;
      text-shadow: none;
    }
    20% {
      opacity: 0;
      text-shadow: none;
    }
    20.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
    }
    20.6% {
      opacity: 0;
      text-shadow: none;
    }
    30% {
      opacity: 0;
      text-shadow: none;
    }
    30.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    30.5% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    30.6% {
      opacity: 0;
      text-shadow: none;
    }
    45% {
      opacity: 0;
      text-shadow: none;
    }
    45.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    50% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55.1% {
      opacity: 0;
      text-shadow: none;
    }
    57% {
      opacity: 0;
      text-shadow: none;
    }
    57.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
    }
    60% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
    }
    60.1% {
      opacity: 0;
      text-shadow: none;
    }
    65% {
      opacity: 0;
      text-shadow: none;
    }
    65.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    75% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    75.1% {
      opacity: 0;
      text-shadow: none;
    }
    77% {
      opacity: 0;
      text-shadow: none;
    }
    77.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    85% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    85.1% {
      opacity: 0;
      text-shadow: none;
    }
    86% {
      opacity: 0;
      text-shadow: none;
    }
    86.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    100% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
  }
  @keyframes text-flicker-in-glow {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
      text-shadow: none;
    }
    10.1% {
      opacity: 1;
      text-shadow: none;
    }
    10.2% {
      opacity: 0;
      text-shadow: none;
    }
    20% {
      opacity: 0;
      text-shadow: none;
    }
    20.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
    }
    20.6% {
      opacity: 0;
      text-shadow: none;
    }
    30% {
      opacity: 0;
      text-shadow: none;
    }
    30.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    30.5% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    30.6% {
      opacity: 0;
      text-shadow: none;
    }
    45% {
      opacity: 0;
      text-shadow: none;
    }
    45.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    50% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55.1% {
      opacity: 0;
      text-shadow: none;
    }
    57% {
      opacity: 0;
      text-shadow: none;
    }
    57.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
    }
    60% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
    }
    60.1% {
      opacity: 0;
      text-shadow: none;
    }
    65% {
      opacity: 0;
      text-shadow: none;
    }
    65.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    75% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    75.1% {
      opacity: 0;
      text-shadow: none;
    }
    77% {
      opacity: 0;
      text-shadow: none;
    }
    77.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    85% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    85.1% {
      opacity: 0;
      text-shadow: none;
    }
    86% {
      opacity: 0;
      text-shadow: none;
    }
    86.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    100% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
  }
  
  .text-flicker-in-glow {
	-webkit-animation: text-flicker-in-glow 4s linear both;
	        animation: text-flicker-in-glow 4s linear both;
}



.aIno {
  transition: 0.5s;
}
.aIno:hover {
  color: yellow;
}









:root {
    --fuente: 'Montserrat', sans-serif;
}

/* Estilos para el scrollbar */
::-webkit-scrollbar {
    width: 15px; /* Ancho del scrollbar */
  background-color: #000;
    
  }
  
  /* Estilos para la barra de desplazamiento */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(31, 31, 31); /* Color de la barra de desplazamiento */
    /* border: 1px solid black; */
   
  
  }
  .devx {
    font-weight: 300;
    letter-spacing: -0.35rem;
    
  }
  .devxM {
    font-weight: 300;
    letter-spacing: -0.15rem;
    
  }
 .devx2 {
    font-weight: 300;
    letter-spacing: -0.1rem;
 }

.landing {
    height: 100vh;
    background-color: black;
    margin: 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.img {
    height: 90%;
    width: 50%;
    /* background-color: aqua; */
    display: flex;
    justify-content: end;
}
.img img {
    height: 100%;
}
.txt {
    color: white;
    font-family: var(--fuente);
    align-self: center;
    margin-left: 4%;
    /* margin-top: 5%; */
    /* background-color: #E0E111; */
    /* height: 100%; */
}
.brand {
    /* margin-top: 25%; */
    margin-bottom: 15%;
    font-weight: 800;
    font-size: 1.9em;
}
h1 {
    font-size: 7.1em;
    margin: 0 0;
    font-weight: 600;
    letter-spacing: -6px;
}
.landingDesc {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 2%;
}
.landingBtn {
    background-color: black;
    border: 2px solid #E0E111;
    color: #E0E111;
    font-weight: 600;
    font-size: 1rem;
    padding: 3%;
    text-transform: uppercase;
    transition: 0.3s;
    cursor: pointer;
    font-family: var(--fuente);
    margin-bottom: 15%;
    margin-top: 4%;
}

.btnInfo1 {
    font-family: var(--fuente);
}
.btnInfo2 {
    font-family: var(--fuente);
}
.landingBtn:hover {
    background-color: #E0E111;
    border: 2px solid #E0E111;
    color: #000;
}

.info1 {
    background-color: white;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cuadradoInfo1 {
    display: flex;
    height: 90%;
    width: 90%;
    border: 1.5px solid black;
    border-radius: 50px;
    justify-content: space-between;
}
.h2Info {
    font-size: 3.8rem;
    font-family: var(--fuente);
    font-weight: 500;
    width: 45%;
    letter-spacing: -2px;
    margin-left: 5%;
}
.derInfo1 {
    width: 35%;
    height: 90%;
    /* background-color: #E0E111; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
}
.derInfo1 img {
   width: 90%;
   margin-right: 10%;
   margin-top: 8%;
}
.pInfo1 {
    font-family: var(--fuente);
    font-size: 1.1rem;
    font-weight: 500;
    width: 75%;
    margin-top: 2%;
    margin-bottom: 5%;
}
.btnInfo1 {
    background-color: black;
    border: 2px solid black;
    color: white;
    font-weight: 600;
    margin-bottom: 15%;
    font-size: 1rem;
    padding: 3% 7%;
    text-transform: uppercase;
    transition: 0.3s;
    cursor: pointer;
    text-decoration: none;
}
.btnInfo1:hover {
    background-color: white;
    border: 2px solid #000;
    color: #000;
}

.info2 {
    background-color: #000;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0;
    flex-direction: column;
}
.caracs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-bottom: 2%;
    width: 85%;
}
.carac {
    height: 50vh;
    /* background-color: aliceblue; */
    width: 30%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.imgCarac {
    height: 40%;
}
.h4Carac {
    font-family: var(--fuente);
    font-size: 2rem;
    text-align: center;
    margin: 0 0;
    margin-top: 2%;

}
.pCarac {
    font-family: var(--fuente);
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    margin-top: 4%;
}
.btnInfo2 {
    background-color: #000;
    border: 2px solid #E0E111;
    color: #E0E111;
    font-weight: 600;
    
    font-size: 1rem;
    padding: 1% 5%;
    text-transform: uppercase;
    transition: 0.3s;
    cursor: pointer;
}
.btnInfo2:hover {
    background-color: #E0E111;
    border: 2px solid #E0E111;
    color: #000;
}
.info3 {
    /* background-image: url('../../img/back2.png'); */
    background-color: white;
    margin: 0 0;
    background-size: cover;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}
.izqInfo3 {
    width: 54%;
    margin-right: 3%;
    /* background-color: #E0E111; */
    
   
}
.derInfo3 {
    width: 45%;
    height: 85%;
    
    /* background-color: #E0E111; */
    display: flex;
    justify-content: center;
    /* height: 45vh; */
   
}
.derInfo3 img {
    height: 100%;
    /* border-radius: 50%; */
} 
.info3 h4 {
    font-size: 2.5rem;
    color: black;
    font-family: var(--fuente);
    margin: 0 0;
    margin-bottom: 4%;
    width: 100%;
    font-weight: 700;
    padding-left: 10%;
}
.btnInfo3{
    background-color: #000;
    font-size: 1rem;
    margin-left: 10%;
    /* margin-top: 10%; */
    padding: 2.2% 6%;
    font-weight: 600;
    text-transform: uppercase;
    font-family: var(--fuente);
    transition: 0.5s;
    border: 2px solid black;
}
.btnInfo3:hover {
    background-color: white;
    color: #000;
    /* text-decoration: underline; */
}
.info3 p {
    font-size: 1.2rem;
    color: black;
    font-family: var(--fuente);
    font-weight: 500;
    padding-left: 10%;
    margin-bottom: 1.5%;
}
.info3 h5 {
    font-size: 1.1rem;
    color: black;
    font-family: var(--fuente);
    margin: 0 0;
    font-weight: 700;
    padding-left: 10%;
}
.landing2 {
    height: 70vh;
    background-color: black;
    margin: 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}
.img2 {
    height: 85%;
    width: 30%;
    color: white;
    /* background-color: aqua; */
    display: flex;
    justify-content: end;
    flex-direction: column;
    margin-right: 2.5%;
}
.img2 img {
    height: 100%;
}
.redes {
    display: flex;
    justify-content: space-around;
    
}
.redes h6 {
    font-size: 1.2rem;
    margin: 0 0;
    font-family: var(--fuente);
    font-weight: 500;
}
.redes div {
    display: flex;
    width: 30%;
    justify-content: space-around;
}
a {
    color: white;
    text-decoration: none;
}
.redes {
    color: white;
   
    font-size: 1.5rem;
    text-decoration: none;
}
.aIno {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    margin-right: 2%;
}
.landingDesc2 {
    font-size: 3.2em;
    font-weight: 500;
    margin: 0 0;
    
    
}
.brand2 {
    margin-top: 5%;
    margin-bottom: 15%;
    font-weight: 800;
    font-size: 1.3rem;
}
.txt2 {
    color: white;
    margin-left: 5%;
    font-family: var(--fuente);
}
.mobile {
    display: none;
}
.landingBtn2 {
    background-color: black;
    border: 2px solid #E0E111;
    color: #E0E111;
    font-weight: 600;
    font-size: 1rem;
    padding: 3%;
    text-transform: uppercase;
    transition: 0.3s;
    cursor: pointer;
    font-family: var(--fuente);
    /* margin-bottom: 15%; */
}
.landingBtn2:hover {
    background-color: #E0E111;
    border: 2px solid #E0E111;
    color: #000;
}

@media only screen and (max-width: 850px){
    .btnInfo1:hover {
        background-color: white;
        border: 2px solid #000;
        color: black;
    }
    .btnInfo1 a:hover {
        color: black;
    }
    .imgCarac {
      height: 12.5rem;
  }
    
    .desk {
        display: none;
    }
    .mobile {
        display: block;
    }
    .landingM {
        background-color: #000;
        min-height: calc(var(--vh, 1vh) * 100);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .imgM {
        height: calc(var(--vh, 1vh) * 45);
        margin-top: 0;
    }
    .imgM img {
        height: 100%;
    }
    .txt {
       
        margin-left: 10%;
        color: white;
        align-self: start;
        font-weight: 600;
        font-family: var(--fuente);
    }
    .txt1 {
     margin-bottom: 1%;
        margin-left: 10%;
        color: white;
        align-self: start;
        font-weight: 600;
        font-family: var(--fuente);
    }
    .brand {
        margin-top: 10%;
        margin-bottom: 2%;
    }
    h1 {
        font-size: 3.6rem;
        letter-spacing: -0.2rem;
        /* margin-top: 5%; */
    }
    .landingDesc {
        font-size: 1.6rem;
        margin-bottom: 5%;
    }
    .landingBtn {
        font-size: 1.1em;
        margin-top: 0;
        font-weight: 600;
        font-family: var(--fuente);
        
    }
  .info1 {
    height: inherit;
  }
  .info2 {
    height: inherit;
  }
  .info3 {
    height:auto;
    /* min-height: calc(var(--vh, 1vh) * 80); */

    justify-content: inherit;
    align-items: center;
    width: 100%;
    /* padding-bottom: 5%; */
  }
  .landing2 {
    height: inherit;
    padding-bottom: 2%;
  }
    .cuadradoInfo1 {
        flex-direction: column;
        border-radius: 25px;
        justify-content: space-between;
        align-items: center;
        
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .h2Info {
        font-size: 2.2em;
        width: 85%;
        margin-bottom: 0;
    }
    .derInfo1 {
        width: 100%;
        justify-content: center;
        align-items: center;
      
     margin:  0 0;
    }
    .derInfo1 img {
        height: 100%;
        width: 70%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin: 0 auto;
    }
    .pInfo1 {
        width: 85%;
        margin-left: 10%;
        margin-right: 5%;
    }
    .btnInfo1 {
        margin-left: 10%;
    }

    .caracs {
        flex-direction: column;
    }
    .carac {
        width: 95%;
    }
    .info3 {
        flex-direction: column;
        /* justify-content: baseline; */
        /* min-height: calc(var(--vh, 1vh) * 85); */
    }
    .izqInfo3 {
        display: flex;
        flex-direction: column;
        width: 85%;

        height: 100%;
        background-color: white;
        align-items: start;
        /* justify-content: space-between; */
    }
    .izqInfo3 img {
        /* height: calc(var(--vh, 1vh) * 25); */
        /* border-radius: 50%; */
        /* margin-top: 10%; */
        width: 100%;
       margin-top: 15%;
       margin-bottom: 2%;
        /* background-color: #E0E111; */
    }
    .izqInfo3 h4 {
        margin-top: 5%;
        width: 100%;
        margin-left: 0;
        font-size: 1.8em;
        width: 90%;
        font-family: var(--fuente);
        
    }
    .btnInfo3 {
      margin-bottom: 15%;
      /* text-align: start; */
      /* padding: 3% 30%; */
      /* display: flex; */
      /* width: 70%; */
    }
    .izqInfo3 p {
        font-size: 1.1em;
        /* width: 100%; */
        margin-bottom: 10%;
    }
    .landing2 {
        flex-direction: column;
        height: inherit;
        justify-content: center;
        align-items: center;
    }
    .img2 {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0;
    }
    .img2 img {
        width: 70%;
    } 
    .redes {
        width: 100%;
    }
    .landingDesc2{ 
        text-align: center;
        margin-bottom: 0;
        margin-top: 5%;
    }
    .txt2 {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .landingBtn {
        margin: 0 auto;
        margin-bottom: 4%;
        margin-top: 4%;
    }
    .redes {
        width: 70%;
        margin-top: 25%;
        margin-bottom: 5%;
        color: white;
    }
    .landingDesc2 {
        width: 95%;
    }
    .pCarac {
        width: 95%;
    }
    .txt2 {
        width: 90%;
    }
    .carac {
        margin-top: 5%;
    }
  .separador {
    height: 15em;
    
  } 
  .landingBtn {
    margin-top: 8%;
  }
  .caracs {
    height: 100%;
  }
  .carac {
    height: 100%;
    margin-bottom: 10%;
    margin-top: 10%;
  }
.imgCarac {
  margin-bottom: 5%;
}
.landing2{
  padding: 10% 0;
}
}

@media only screen and (min-width: 851px) and (max-width: 1300px) {
    /* h1 {
        font-size: 6em;
    } */
  }

@media only screen and (min-width: 1500px) {
    .txt {
        margin-top: 8%;
    }
    .brand {
        font-size: 2em;
    } 
    h1 {
        font-size: 8em;
    }
    .info3 h4 {
        font-size: 2.8em;
    }
    .info3 p {
        font-size: 1.5em;
    }
    .info3 h5 {
        font-size: 1.6em;
    }
    .info1 p {
        font-size: 1.5em;
    }
    .btnInfo1 {
        font-size: 1.3em;
        
    }
    .h2Info {
        font-size: 5em;
    }
    .imgCarac {
        height: 55%;
    }
    .h4Carac {
        font-size: 2.8em;
    }
    .pCarac {
        font-size: 1.5em;
        margin-top: 4%;
    }
    .btnInfo2 {
        font-size: 1.6em;
    }
    .landingDesc2 {
        font-size: 4em;
    }
    .brand2 {
        font-size: 2em;
    }
    .landingBtn {
        font-size: 1.6em;
    }
}