.headerL {
    margin-top: 0;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
}

.logoHeader {
    font-family: var(--fuente);
    align-items: center;
}
.linksHeader {
   
    /* background-color: aqua; */
    width: 32%;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    
}
.linkHeader {
    font-family: var(--fuente);
    color: white;
    font-weight: 500;
    text-decoration: none;
    margin-right: 0%;
    font-size: 1.3em;
    transition: 0.2s;
}
.linkHeader:hover {
    color: #E0E111;
}
.afiliacionDiv {
    width: 25%;
    display: flex;
    justify-content: end;
   ;
}
.afiliacionHeader {
    font-family: var(--fuente);
    color: var(--color1);
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    /* background-color: #E0E111; */
    padding: 1.2% 7%;
    margin-right: 6%;
    /* border-radius: 10px; */
    transition: 0.3s;
   

    background-color: black;
    border: 2px solid #E0E111;
    color: #E0E111;
    font-weight: 600;
    
    font-size: 1rem;
    padding: 3% 5%;
    text-transform: uppercase;
    transition: 0.3s;
    cursor: pointer;
    font-family: var(--fuente);
    
}
.afiliacionHeader:hover {
    /* color: var(--color1); */
    background-color: yellow;
    color: black;
    border: 2px solid #E0E111;
}

.afiliacionHeader2 {
  font-family: var(--fuente);
  color: var(--color1);
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  /* background-color: #E0E111; */
  padding: 1.2% 7%;
  margin-right: 6%;
  /* border-radius: 10px; */
  transition: 0.3s;
 

  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-weight: 600;
  
  font-size: 1rem;
  padding: 3% 5%;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
  font-family: var(--fuente);
  
}
.afiliacionHeader2:hover {
  /* color: var(--color1); */
  background-color: white;
  color: black;
  border: 2px solid transparent;
}


.divLogo {
    margin-left: 2%;
    width: 18%;
    align-items: center;
    display: flex;
    /* background-color: aqua; */
}
.logoHeader {
  font-size: 1.8rem;
  margin-left: 2%;

  
}

.jmbH {
    font-size: 1.9rem;
    color: white;
    letter-spacing: -1px;
    font-weight: 800;
}
.devH {
    font-size: 1.2rem;
    color: var(--color1);
    
    margin-left: 0.3%;
}

@media only screen and (max-width: 850px){ 
    .headerL {
        display: none;
        color: var(--header-text-color);
  background-color: var(--header-bg-color);
  filter: invert(1);
    }
    .logoHeader {
        margin-left: 5%;
        /* background-color: #E0E111; */
        font-size: 1.4rem;
        width: 100%;
    }
    .iconoHeader {
      /* color: var(--color1); */
    }
    .divLogo {
      width: 100%;
    }
    
    .headLm {
        display: flex;
        justify-content: space-between;
      position: absolute;
        width: 100%;
        align-items: center;
    }
    .headerLM {
        
        margin-top: 0;
        transition: 0.3s;
        width: 100%;
       
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
       
    }
    

    .bgInHF {
      -webkit-animation: bg-inHF 1s linear both;
              animation: bg-inHF 1s linear both;
    }
    
    
    
    @-webkit-keyframes bg-inHF {
      0% {
        background: transparent;
      }
     
      100% {
        background-color: rgba(0, 0, 0, 0.109);
      }
    }
    @keyframes bg-inHF {
      0% {
        background: transparent;
      }
     
      100% {
        background-color: rgba(0, 0, 0, 0.109);
      }
    }
    


    .linksHeaderFM {
        display: flex;
        flex-direction: column;
        padding-bottom: 4%;
        width: 100%;
        padding-top: 22%;
       
    }
    .linkHeaderFM {
        
        margin-left: 8.5%;
font-family: var(--fuente);
color: white;
font-size: 1.5rem;
text-decoration: none;
font-weight: 500;

    }
    .afilicionDivFM {
        display: flex;
        width: 100%;
        /* align-items: center;
        justify-content: center; */
    }
    .afiliacionHeaderFM {
        margin-left: 8.5%;
        font-family: var(--fuente);
        color: white;
        font-size: 1.1rem;
        text-decoration: none;
        background-color: var(--color1);
        padding: 0.5% 2%;
        border-radius: 10px;
        width: 50%;
        margin-top: 5%;
        text-align: center;
    }
    .iconoHeader {
      font-size: 1.5em;
     margin-left: 20%; 
    }
    .barsFM {
        color: white;
        margin-right: 5%;
        font-size: 1.7rem;
    }
   
}


.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-7 21:51:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.flip-in-hor-bottom {
	-webkit-animation: flip-in-hor-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-3 17:20:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
              transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
              transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }
  


  .flip-in-hor-top {
	-webkit-animation: flip-in-hor-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes flip-in-hor-top {
    0% {
      -webkit-transform: rotateX(-80deg);
              transform: rotateX(-80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-hor-top {
    0% {
      -webkit-transform: rotateX(-80deg);
              transform: rotateX(-80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }
  


  .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-3 17:23:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  